import Layout from "../components/common/Layout/Layout"
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const GalleryPage = () => {
    const data = useStaticQuery(graphql`
        query CloudinaryImages {
            allCloudinaryMedia {
              edges {
                node {
                  secure_url
                }
              }
            }
          }
          `
    );
    const clImages = data.allCloudinaryMedia.edges;
    
    const [galleryState, setGalleryState] = useState({
        isOpen: false,
        openIndex: null
    });

    const handleImageOpen = e => {
        console.log(e.target);

        setGalleryState({
            ...galleryState
        })
    }

    return (
        <Layout>
            <div className="container image-grid">
            {clImages.map((image, index) => (
                  <div key={`${index}-cl`} onClick={handleImageOpen}>
                    <img src={image.node.secure_url} />
                  </div>
                ))
            }
            </div>
        </Layout>
    )
};

export default GalleryPage;